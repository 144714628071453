import { render, staticRenderFns } from "./ServiceEligibilities.vue?vue&type=template&id=3825520f&scoped=true&"
import script from "./ServiceEligibilities.vue?vue&type=script&lang=js&"
export * from "./ServiceEligibilities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3825520f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/byt3sage/code/ayup/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3825520f')) {
      api.createRecord('3825520f', component.options)
    } else {
      api.reload('3825520f', component.options)
    }
    module.hot.accept("./ServiceEligibilities.vue?vue&type=template&id=3825520f&scoped=true&", function () {
      api.rerender('3825520f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/index/taxonomies/ServiceEligibilities.vue"
export default component.exports